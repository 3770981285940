import { Link } from 'react-router-dom';
import type { ColumnType } from 'antd/lib/table';
import { PresetStatusColorTypes } from 'antd/lib/_util/colors';
import Tag from 'antd/es/tag';
import Tooltip from 'antd/es/tooltip';
import Popover from 'antd/es/popover';
import ChatIcon from '@heroicons/react/24/outline/DocumentTextIcon';
import { format } from 'date-fns';
import { IRepository } from '../utils/api';

export function RenderCategories(categories: string[]): any[] {
  return categories.map((x, index) => (
    <Tag key={x + index} color={PresetStatusColorTypes[index]}>
      {x}
    </Tag>
  ));
}

export const ColumnID: ColumnType<IRepository> = {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  sorter: true,
  width: 20,
  render: (value) => {
    return <div>{value}</div>;
  },
};

export const ColumnCategory: ColumnType<IRepository> = {
  title: '카테고리',
  dataIndex: 'path',
  key: 'path',
  width: 200,
  render: (path: string) => (
    <div className='w-min flex flex-row flex-nowrap'>
      {path
        ? RenderCategories(path.split(',').filter((x: string) => x.length))
        : null}
    </div>
  ),
};

export const ColumnTitle: ColumnType<IRepository> = {
  title: '제목',
  dataIndex: 'title',
  key: 'title',
  width: 200,
  render: (value, record) => (
    <Link to={'/repositories/' + record.id} className='truncate'>
      {value}
    </Link>
  ),
};

export const ColumnDescription: ColumnType<IRepository> = {
  title: '설명',
  dataIndex: 'description',
  key: 'description',
  width: 380,
  render: (value, record) =>
    value ? (
      <div className='flex justify-between'>
        <span className='w-fit'>
          {value.slice(0, 40)}
          {value.length > 40 ? '...' : null}
        </span>

        <Popover
          title={<div className='text-xl'>{record.title}</div>}
          trigger='click'
          content={
            <textarea
              className='w-[500px] h-[300px] border-none bg-green-200 resize block'
              readOnly
              value={value}
            />
          }
        >
          <ChatIcon className='h-5 w-5 text-blue-400' />
        </Popover>
      </div>
    ) : (
      '-'
    ),
};

export const ColumnAuthorNickname: ColumnType<IRepository> = {
  title: '작성자',
  dataIndex: 'authorNickname',
  key: 'authorNickname',
  width: 80,
  render: (value) => <span className='truncate'>{value}</span>,
};

export const ColumnCreatedAt: ColumnType<IRepository> = {
  title: '작성일',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 100,
  render: (date: Date) => (
    <Tooltip title={date.toLocaleString()}>
      {format(date, 'yyyy/MM/dd')}
    </Tooltip>
  ),
  sorter: true,
};

export const ColumnUpdatedAt: ColumnType<IRepository> = {
  title: '수정일',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  width: 100,
  render: (date: Date) => (
    <Tooltip title={date.toLocaleString()}>
      {format(date, 'yyyy/MM/dd')}
    </Tooltip>
  ),
  sorter: true,
};

export const ColumnRefRepository: ColumnType<IRepository> = {
  title: '참조',
  dataIndex: 'refRepositoryId',
  key: 'refRepositoryId',
  width: 70,
  render: (value, record) =>
    value ? <span className='truncate'>참조</span> : null,
};
