import { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import API from '../utils/api';
import { CascadeSelector } from '../components/CascadeSelector';
import { ArrayToCascadeTree } from '../utils/tree';

function ColoredErrorMessage({ name }: { name: string }) {
  return (
    <ErrorMessage component='a' className='' name={name}>
      {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
    </ErrorMessage>
  );
}

function Title() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:pt-5'>
      <label
        htmlFor='title'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        제목
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='title'
          name='title'
          type='text'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='title' />
      </div>
    </div>
  );
}

function Categories({
  values,
  onChange,
}: {
  values: string[];
  onChange: (value: any) => void;
}) {
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchCategoryData() {
      setLoading(true);
      try {
        const resp = await API.Category.Read();
        setCategoryData(resp.data);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
    fetchCategoryData();
  }, []);

  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start'>
      <label
        htmlFor='categories'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        카테고리
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-8'>
        <CascadeSelector
          className='w-full'
          options={ArrayToCascadeTree(categoryData)}
          value={values}
          onChange={onChange}
          isLoading={loading}
        />
        <ColoredErrorMessage name='categories' />
      </div>
    </div>
  );
}

function Description() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start'>
      <label
        htmlFor='description'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        설명
      </label>
      <div className='sm:col-span-10'>
        <Field
          id='description'
          name='description'
          type='textarea'
          component='textarea'
          className='w-full shadow-sm border-gray-300 rounded-md sm:h-52'
          style={{ whiteSpace: 'pre-wrap' }}
        />
        <ColoredErrorMessage name='description' />
      </div>
    </div>
  );
}

export { Title, Categories, Description };
