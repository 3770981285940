import { Fragment } from 'react';
import { PresetStatusColorTypes } from 'antd/lib/_util/colors';
import Tag from 'antd/es/tag';
import { Dialog, Transition } from '@headlessui/react';

export function RenderCategories(categories: string[]): any[] {
  return categories.map((x, index) => (
    <Tag key={x + index} color={PresetStatusColorTypes[index]}>
      {x}
    </Tag>
  ));
}

export function RenderCategoriesFromPath(path: string) {
  return RenderCategories(path.split(',').filter((x: string) => x.length));
}

export function RenderFileSize(size: number) {
  const KB = 1024;
  const MB = KB * 1024;

  if (size < KB) {
    return size.toFixed(0) + ' B';
  } else if (size < MB) {
    return (size / KB).toFixed(0) + ' KB';
  } else {
    return (size / MB).toFixed(0) + ' MB';
  }
}

export function ModalHeadlessUI({
  open,
  body,
}: {
  open: boolean;
  body: React.ReactNode;
}) {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-baseline justify-center p-4 text-center mt-32'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                {body}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
