import { Link } from 'react-router-dom';
import Logout from '@heroicons/react/24/outline/ArrowLeftOnRectangleIcon';
import { useAuth } from '../context/AuthProvider';

function Menu() {
  return (
    <nav className='flex items-center font-normal gap-4 ml-10'>
      <Link to='/' className='text-black hover:text-gray-600'>
        게시판
      </Link>
      <Link to='/category' className='text-black hover:text-gray-600'>
        카테고리
      </Link>
    </nav>
  );
}

export default function Header() {
  const auth = useAuth();
  const { username, nickname } = auth.user;

  return (
    <div className='h-16 bg-green-600 flex justify-between px-10'>
      <div className='flex'>
        <img className='m-3' src='/download.png' alt='' />
        <Link
          to='/'
          className='mx-3 my-auto text-3xl font-bolder text-black hover:text-black'
        >
          리산테크 자료실
        </Link>
        <Menu />
      </div>
      <div className='flex'>
        <div className='my-auto'>{nickname}</div>
        <div className='ml-2 my-auto'>({username})</div>
        <button
          type='button'
          className='ml-4'
          onClick={async () => {
            // try {
            //   await API.User.Logout();
            // } catch (e) {
            //   console.log(e);
            // }

            auth.signout(() => {});
          }}
        >
          <span className='sr-only'>View notifications</span>
          <Logout className='h-6 w-6' aria-hidden='true' />
        </button>
      </div>
    </div>
  );
}
