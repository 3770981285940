import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Divider from 'antd/es/divider';
import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import { toast } from 'react-toastify';
import API from '../utils/api';
import { ModalHeadlessUI } from './Common';
import { IRelease } from './ReleaseTableColumns';

interface ReleaseEditProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  release: IRelease;
  onUpdated: () => void;
}

export function ReleaseEdit({
  open,
  setOpen,
  release,
  onUpdated,
}: ReleaseEditProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      version: release.version,
      status: release.status,
      description: release.description,
    },
  });
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    reset({
      version: release.version,
      status: release.status,
      description: release.description,
    });
  }, [release, reset]);

  async function onSubmit(data: any) {
    // console.log('onSubmit', data);

    const formData = new FormData();
    formData.set('version', data.version);
    formData.set('status', data.status);
    formData.set('description', data.description);

    // formData.set('file', data.file[0]);
    // const filepath = path.replaceAll(',', '/');
    // console.log('filepath', filepath);
    // formData.set('filepath', filepath);
    // formData.set('filehash', await sha256file(data.file[0]));

    try {
      setIsUploading(true);
      await API.Releases.Update(release.id, data);
      onUpdated();
      toast.success('릴리즈 저장 성공');
    } catch (e) {
      console.log(e);
      toast.error('릴리즈 저장 실패');
    } finally {
      setIsUploading(false);
    }

    reset();
    setOpen(false);
  }
  function onCancel() {
    reset();
    setOpen(false);
  }

  return (
    <ModalHeadlessUI
      open={open}
      body={
        <Spin spinning={isUploading}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='text-xl font-bolder w-full'>릴리즈 편집</div>
            <Divider />
            <div className='space-y-5 px-10'>
              <div className='grid grid-cols-12 items-center'>
                <div className='col-span-3'>
                  <label
                    htmlFor='version'
                    className='block text-sm font-medium text-gray-700'
                  >
                    버전
                  </label>
                </div>
                <div className='col-span-9'>
                  <input
                    type='text'
                    className='block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                    {...register('version', { required: true })}
                  />
                  {errors.version?.type === 'required' && (
                    <div className='text-red-500'>버전을 입력해주세요</div>
                  )}
                </div>
              </div>

              <div className='grid grid-cols-12 items-center'>
                <div className='col-span-3'>상태</div>

                <select
                  className='col-span-9 w-full shadow-sm border-gray-300 rounded-md'
                  {...register('status', { required: true })}
                >
                  <option value='알파'>알파</option>
                  <option value='승인'>승인</option>
                </select>
              </div>

              <div className='grid grid-cols-12 items-center'>
                <label
                  htmlFor='file'
                  className='col-span-3  block text-sm font-medium text-gray-700'
                >
                  파일
                </label>
                <div className='col-span-9'>
                  <div>{release.files[0].name}</div>
                  {/* <input
                    type='file'
                    {...register('file', { required: true })}
                  />
                  {errors.file?.type === 'required' && (
                    <div className='text-red-500'>파일을 선택해주세요</div>
                  )}
                  {isUploading ? <Progress percent={uploadProgress} /> : null} */}
                </div>
              </div>

              <div className='grid grid-cols-12 items-center'>
                <div className='col-span-3'>설명</div>
                <div className='col-span-9'>
                  <textarea
                    className='w-full shadow-sm border-gray-300 rounded-md sm:h-52'
                    style={{ whiteSpace: 'pre-wrap' }}
                    {...register('description')}
                  />
                </div>
              </div>

              <Divider />
              <div className='flex justify-center space-x-5'>
                <Button onClick={onCancel}>취소</Button>
                <Button type='primary' htmlType='submit'>
                  저장
                </Button>
              </div>
            </div>
          </form>
        </Spin>
      }
    />
  );
}
