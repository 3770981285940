import { useCallback, useEffect, useState } from 'react';
import TreeView from '../components/TreeView';
import Table from 'antd/es/table';
import API from '../utils/api';
import { IRepository } from '../utils/api';
import {
  ColumnID,
  ColumnCategory,
  ColumnTitle,
  ColumnAuthorNickname,
  ColumnRefRepository,
  ColumnCreatedAt,
  ColumnUpdatedAt,
} from '../components/RepositoryTable';
import { useUsersInfoContext } from '../context/UserInfoProvider';

export default function Category() {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState<string>('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [totalDocs, setTotalDocs] = useState(1);
  const [repositories, setRepositories] = useState<any[]>([]);
  const [sort, setSort] = useState({ id: -1 });
  const { getNickname } = useUsersInfoContext();

  const fetchRepositories = useCallback(async () => {
    const query = new URLSearchParams();

    query.set('path', path);
    query.set('sort', JSON.stringify(sort));
    query.set('page', page.toString());
    query.set('pageSize', pageSize.toString());

    setLoading(true);
    try {
      const resp = await API.Repositories.Read(query.toString());
      const { docs, totalDocs } = resp.data;

      const tableData: IRepository[] = docs.map((doc: any) => {
        return {
          ...doc,
          key: doc._id,
          createdAt: new Date(doc.createdAt),
          updatedAt: new Date(doc.updatedAt),
          authorNickname: getNickname(doc.author) ?? doc.author,
        };
      });

      setRepositories(tableData);
      setTotalDocs(totalDocs);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }, [path, getNickname, page, pageSize, sort]);

  useEffect(() => {
    fetchRepositories();
  }, [path, fetchRepositories]);

  const Columns = [
    ColumnID,
    ColumnCategory,
    ColumnTitle,
    ColumnAuthorNickname,
    ColumnCreatedAt,
    ColumnUpdatedAt,
    ColumnRefRepository,
  ];

  return (
    <div className='mt-3 flex justify-center'>
      <div className='w-[1000px]'>
        <TreeView
          onSelect={(node) => {
            const fullpath = node ? (node.path ?? ',') + node.title + ',' : '';
            setPath(fullpath);
          }}
        />
        <Table
          bordered
          columns={Columns}
          dataSource={repositories}
          loading={loading}
          pagination={{
            position: ['bottomCenter'],
            current: page,
            pageSize: pageSize,
            total: totalDocs,
            showSizeChanger: true,
            pageSizeOptions: [4, 10, 20, 30, 50],
          }}
          onChange={(newPagination, filters, sorter, extra) => {
            switch (extra.action) {
              case 'paginate': {
                setPage(newPagination.current ?? 1);
                setPageSize(newPagination.pageSize ?? 10);
                break;
              }
              case 'sort': {
                let newSort: any = {};

                if (!Array.isArray(sorter) && sorter.field) {
                  newSort[sorter.field?.toString()] =
                    sorter.order === 'ascend'
                      ? 1
                      : sorter.order === 'descend'
                      ? -1
                      : undefined;
                }

                setSort(newSort);
                break;
              }
              case 'filter':
                break;
            }
          }}
        />
      </div>
    </div>
  );
}
