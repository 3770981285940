import './App.less';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider, RequireAuth } from './context/AuthProvider';
import Layout from './layout/Layout';
import Login from './pages/Login';
import Category from './pages/Category';
import UserRegisterPage from './pages/UserRegisterPage';
import RepositoryListPage from './pages/RepositoryListPage';
import RepositoryViewPage from './pages/RepositoryViewPage';
import RepositoryNewPage from './pages/RepositoryNewPage';
import RepositoryEditPage from './pages/RepositoryEditPage';

function App() {
  console.log(
    process.env.REACT_APP_NAME,
    process.env.REACT_APP_VERSION,
    process.env.NODE_ENV,
    process.env.REACT_APP_BASENAME
  );

  return (
    <AuthProvider>
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        <Routes>
          <Route path='/health' element={<div>OK</div>} />
          <Route path='/login' element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path='/' element={<Layout />}>
              <Route
                path='/'
                element={<Navigate to='/repositories' replace />}
              />
              <Route path='/category' element={<Category />} />
              <Route path='/register' element={<UserRegisterPage />} />

              {/* Repositories */}
              <Route path='/repositories' element={<RepositoryListPage />} />
              <Route
                path='/repositories/:id'
                element={<RepositoryViewPage />}
              />
              <Route
                path='/repositories/:id/edit'
                element={<RepositoryEditPage />}
              />
              <Route path='/repositories/new' element={<RepositoryNewPage />} />
            </Route>
          </Route>
          <Route path='/*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthProvider>
  );
}

export default App;
