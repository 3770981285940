export async function FileReadAsync(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async function (event: ProgressEvent<FileReader>) {
      resolve(event.target?.result as ArrayBuffer);
    };
    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject();
    };
    reader.readAsArrayBuffer(file);
  });
}

export async function sha256(data: ArrayBuffer) {
  return await crypto.subtle.digest('SHA-256', data);
}

export function ArrayBuffer2HexString(buffer: ArrayBuffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('');
}

export async function sha256file(file: File) {
  return ArrayBuffer2HexString(await sha256(await FileReadAsync(file)));
}
