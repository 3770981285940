import { useCallback, useEffect, useState } from 'react';
import Tree from 'antd/es/tree';
import Spin from 'antd/es/spin';
import { ArrayToTree, TreeNode } from '../utils/tree';
import API from '../utils/api';

type Key = string | number;

interface CategoryTreeProps {
  onSelect: (node: TreeNode | null) => void;
}

export function CategoryTree({ onSelect }: CategoryTreeProps) {
  const [treeData, setTreeData] = useState<any[]>([]);
  //   const [allKeys, setAllKeys] = useState<Key[]>([]);
  //   const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await API.Category.Read();
      const data = resp.data;

      //   setAllKeys(data.map((x: any) => x._id));
      setTreeData(data);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading}>
      {/* <div>
        <Button
          type={expandedKeys.length > 0 ? 'primary' : 'default'}
          className='col-span-5'
          onClick={() => {
            setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
          }}
        >
          {expandedKeys.length > 0 ? '모두 접기' : '모두 펼치기'}
        </Button>
      </div> */}
      <Tree
        height={700}
        // showLine={{ showLeafIcon: false }}
        defaultExpandAll={false}
        expandAction={'click'}
        // expandedKeys={expandedKeys}
        autoExpandParent={true}
        treeData={ArrayToTree(treeData)}
        onSelect={(key, info) => {
          setSelectedKeys(key);
          onSelect(info.selected ? info.node : null);
        }}
        selectedKeys={selectedKeys}
        // onExpand={(keys) => {
        //   setExpandedKeys(keys);
        // }}
        onRightClick={({ event, node }) => {
          setSelectedKeys([node.key]);
        }}
        draggable={false}
        // onDragEnter={(info) => {
        //   console.log(info);
        // }}
        onDrop={(info) => {
          console.log(info);
        }}
      />
    </Spin>
  );
}
