import { useState, useEffect, ReactNode } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spin from 'antd/es/spin';
import Tooltip from 'antd/es/tooltip';
import Popconfirm from 'antd/es/popconfirm';
import API from '../utils/api';
import { toast } from 'react-toastify';
import { RenderCategories } from '../components/Common';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import { ReleaseTable } from '../components/ReleaseTable';
import Button from 'antd/es/button';

import { ReleaseNew } from '../components/ReleaseNew';
import { ReleaseEdit } from '../components/ReleaseEdit';
import { IRepository } from '../utils/api';
import { useUsersInfoContext } from '../context/UserInfoProvider';
import { IRelease } from '../components/ReleaseTableColumns';

function LabeledValue({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <div className='grid grid-cols-12'>
      <div className='col-span-2 font-semibold'>{label}</div>
      <div className='col-span-10'>{value}</div>
    </div>
  );
}

function Menu({
  repository,
  onDelete,
}: {
  repository: IRepository;
  onDelete: () => void;
}) {
  return (
    <div className='flex space-x-3 items-center'>
      <Tooltip title='편집'>
        <Link id='edit' to={'/repositories/' + repository.id + '/edit'}>
          <EditOutlined />
        </Link>
      </Tooltip>
      <Popconfirm
        placement='bottomLeft'
        okText='예'
        showCancel={false}
        title={<div>정말 삭제하시겠습니까?</div>}
        onConfirm={onDelete}
      >
        <button className='text-red-500'>
          <Tooltip title='삭제'>
            <DeleteOutlined />
          </Tooltip>
        </button>
      </Popconfirm>
      {!repository.refRepositoryId ? (
        <Tooltip title='참조하여 게시판 등록'>
          <Link to={'/repositories/new'} state={{ refRepository: repository }}>
            <CopyOutlined />
          </Link>
        </Tooltip>
      ) : null}
    </div>
  );
}

export default function RepositoryViewPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [repository, setRepository] = useState<IRepository | null>(null);

  const [openNewRelease, SetOpenNewRelease] = useState(false);
  const [openReleaseEdit, setOpenReleaseEdit] = useState(false);
  const [releaseEditing, setReleaseEditing] = useState<IRelease>();

  const { getNickname } = useUsersInfoContext();
  const params = useParams();
  const id = parseInt(params.id ?? '-1');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const resp = await API.Repositories.ReadOneById(id);
        setRepository(resp);
        setIsLoaded(true);
      } catch (e) {
        console.log(e);
        navigate('repositories');
      }
      setIsLoading(false);
    }

    fetchData();
  }, [id, navigate]);

  return (
    <div>
      <div className='w-100 mt-8 flex justify-center'>
        <Spin spinning={isLoading}>
          {isLoaded && repository ? (
            <div className='p-5 w-[1000px] space-y-5'>
              <div className='flex space-x-10 leading-6 font-bolder text-gray-900'>
                <div className='text-2xl'>{repository.title}</div>
                <Menu
                  repository={repository}
                  onDelete={async () => {
                    setIsLoading(true);
                    try {
                      const resp = await API.Repositories.Delete(id);
                      console.log(resp);
                      toast.success('삭제를 성공했습니다');
                      navigate(-1);
                    } catch (e) {
                      console.log(e);
                      toast.success('삭제를 실패했습니다');
                    }
                    setIsLoading(false);
                  }}
                />
              </div>

              <div className='space-y-5'>
                <LabeledValue
                  label='카테고리'
                  value={
                    <div>
                      {repository.path
                        ? RenderCategories(
                            repository.path
                              .split(',')
                              .filter((x: string) => x.length)
                          )
                        : null}
                    </div>
                  }
                />

                <LabeledValue
                  label='작성자'
                  value={getNickname(repository.author)}
                />

                <LabeledValue
                  label='작성일'
                  value={new Date(repository.createdAt).toLocaleString()}
                />

                <LabeledValue
                  label='수정일'
                  value={
                    repository.updatedAt
                      ? new Date(repository.updatedAt).toLocaleString()
                      : '-'
                  }
                />

                <LabeledValue
                  label='설명'
                  value={
                    <div className='w-full h-auto shadow-sm p-5 align-top border-gray-300 rounded-md bg-green-400'>
                      <div
                        className='break-words'
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {repository.description}
                      </div>
                    </div>
                  }
                />

                {repository.refRepository ? (
                  <div className='grid grid-cols-12 items-center'>
                    <div className='col-span-2 text-lg font-bolder'>참조:</div>
                    <Link
                      className='col-span-10'
                      to={'/repositories/' + repository.refRepository.id}
                      reloadDocument={true}
                    >
                      {repository.refRepository.title}
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Button
                      type='primary'
                      onClick={() => {
                        SetOpenNewRelease(true);
                      }}
                    >
                      새 릴리즈 추가
                    </Button>
                    <ReleaseNew
                      repositoryId={repository._id}
                      path={repository.path ?? ''}
                      open={openNewRelease}
                      setOpen={SetOpenNewRelease}
                      onAdded={async () => {
                        try {
                          const resp = await API.Repositories.Update(
                            repository.id,
                            {}
                          );
                          setRepository(resp.data);
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Spin>
        {releaseEditing && repository ? (
          <ReleaseEdit
            open={openReleaseEdit}
            release={releaseEditing}
            setOpen={setOpenReleaseEdit}
            onUpdated={async () => {
              console.log('releaseEditing onUpdate record');
              try {
                const resp = await API.Repositories.Update(repository.id, {});
                setRepository(resp.data);
              } catch (e) {
                console.log(e);
              }
              setReleaseEditing(undefined);
            }}
          />
        ) : null}
      </div>
      {repository ? (
        <ReleaseTable
          readonly={repository.refRepositoryId !== undefined}
          repositoryId={repository.refRepositoryId ?? repository._id}
          repositoryUpdatedAt={repository.updatedAt}
          onUpdate={(record: IRelease) => {
            setReleaseEditing(record);
            setOpenReleaseEdit(true);
          }}
        />
      ) : null}
    </div>
  );
}
