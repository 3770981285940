import { useCallback, useEffect, useState } from 'react';
import Tree from 'antd/lib/tree';
import Input from 'antd/lib/input';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import Select from 'antd/lib/select';
import Spin from 'antd/lib/spin';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { toast } from 'react-toastify';
import API from '../utils/api';
import { ArrayToTree, TreeNode } from '../utils/tree';

type Key = string | number;

//regexp reserved: \ ^ $ . | [ ] ( ) * + ? { }
//category path seperator: , /
const InvalidCharacters2 = ' , /';
const InvalidCharacters = '\\ ^ $ . | [] () * + ? {}' + InvalidCharacters2;
function CategoryNameValidation(name: string) {
  // const regexp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi;

  const regexp = /[/^$.|[\]()*+?{},\\/]/gi;
  return regexp.test(name);
}

function NodeNew({
  tags,
  onSubmit,
  disabled,
}: {
  tags: string[];
  onSubmit: (v: string) => void;
  disabled?: boolean;
}) {
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);

  return (
    <div>
      <div className='grid grid-cols-12 gap-4 items-center'>
        <div className='col-span-2 flex flex-row items-center gap-1'>
          추가
          <PlusOutlined />
        </div>

        <Input
          className='col-span-4'
          value={value}
          onChange={(e) => {
            setIsError(CategoryNameValidation(e.target.value));
            setValue(e.target.value);
          }}
          allowClear={true}
        />

        <Select
          className='col-span-4'
          value={value}
          onChange={(v: any) => {
            setValue(v);
          }}
          disabled={disabled}
        >
          {tags.map((x) => (
            <Select.Option key={x}>{x}</Select.Option>
          ))}
        </Select>

        <Popconfirm
          title='새 노드를 추가하시겠습니까'
          showCancel={false}
          onConfirm={() => onSubmit(value)}
          disabled={disabled || !value}
        >
          <Button
            className='col-start-11 col-span-2'
            type='primary'
            disabled={disabled || !value || isError}
          >
            저장
          </Button>
        </Popconfirm>
      </div>
      {isError ? (
        <div className='grid grid-cols-12 gap-4 items-center'>
          <div className='col-start-3 col-span-9'>
            <span className='text-red-500'>지원되지 않는 문자 포함:</span>
            <span className='text-violet-600'> {InvalidCharacters}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function NodeEdit({
  initialValue,
  onSubmit,
  disabled,
}: {
  initialValue: string;
  onSubmit: (v: string) => void;
  disabled?: boolean;
}) {
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div>
      <div className='grid grid-cols-12 gap-4 items-center'>
        <div className='col-span-2 flex flex-row items-center gap-1'>
          편집
          <EditOutlined />
        </div>
        <Input
          className='col-span-4'
          value={value}
          onChange={(e) => {
            setIsError(CategoryNameValidation(e.target.value));
            setValue(e.target.value);
          }}
          disabled={initialValue ? false : true}
        />
        <Popconfirm
          title='저장하시겠습니까'
          okText='저장'
          showCancel={false}
          onConfirm={() => onSubmit(value)}
          disabled={disabled || !value}
        >
          <Button
            className='col-start-11 col-span-2'
            type='primary'
            disabled={disabled || !value || isError}
          >
            저장
          </Button>
        </Popconfirm>
      </div>
      {isError ? (
        <div className='grid grid-cols-12 gap-4 items-center'>
          <div className='col-start-3 col-span-9 text-red-500'>
            지원되지 않는 문자 포함 {InvalidCharacters}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function NodeDelete({
  name,
  onSubmit,
  disabled,
}: {
  name: string;
  onSubmit: () => void;
  disabled?: boolean;
}) {
  return (
    <div className='grid grid-cols-12 gap-4 items-center'>
      <div className='col-span-2 flex flex-row items-center gap-1'>
        삭제
        <DeleteOutlined />
      </div>
      <Input className='col-span-4' value={name} disabled={true} />

      <Popconfirm
        placement='bottomLeft'
        title={
          <div>
            <div>모든 자식 노드까지 삭제됩니다</div>
            <div>정말 삭제하시겠습니까</div>
          </div>
        }
        okText='삭제'
        showCancel={false}
        onConfirm={onSubmit}
        disabled={disabled}
      >
        <Button
          className='col-start-11 col-span-2'
          type='primary'
          disabled={disabled}
        >
          삭제
        </Button>
      </Popconfirm>
    </div>
  );
}

export default function TreeView({
  onSelect,
}: {
  onSelect: (node: TreeNode | null) => void;
}) {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [allKeys, setAllKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [treeData, setTreeData] = useState<any[]>([]);
  const [selectedNode, setSelectedNode] = useState<TreeNode | null>(null);
  const [draggable] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await API.Category.Read();
      const data = resp.data;

      setAllKeys(data.map((x: any) => x._id));
      setTreeData(data);
    } catch (e) {
      console.error(e);
    }

    setSelectedKeys([]);
    setSelectedNode(null);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Spin spinning={loading}>
      <div className='h-[515px] p-5 space-y-8'>
        <h3 className='text-2xl leading-6 font-bolder text-gray-900'>
          카테고리 편집
        </h3>
        <div className='flex space-x-5'>
          <Tree
            height={400}
            className='w-1/2'
            expandAction={'click'}
            // showLine={{ showLeafIcon: false }}
            defaultExpandAll={false}
            expandedKeys={expandedKeys}
            treeData={ArrayToTree(treeData)}
            onSelect={(key, info) => {
              setSelectedKeys(key);
              setSelectedNode(info.selected ? info.node : null);
              onSelect(info.selected ? info.node : null);
            }}
            selectedKeys={selectedKeys}
            onExpand={(keys) => {
              setExpandedKeys(keys);
            }}
            onRightClick={({ event, node }) => {
              setSelectedKeys([node.key]);
              setSelectedNode(node);
            }}
            draggable={draggable}
            // onDragEnter={(info) => {
            //   console.log(info);
            // }}
            onDrop={(info) => {
              console.log(info);
            }}
          />

          <div className='w-1/2 space-y-5'>
            <div className='grid grid-cols-12 gap-4'>
              <Button
                loading={loading}
                className='col-start-3 col-span-5'
                type='primary'
                onClick={async () => {
                  setLoading(true);
                  try {
                    await fetchData();
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                }}
              >
                새로고침
              </Button>
              <Button
                type={expandedKeys.length > 0 ? 'primary' : 'default'}
                className='col-span-5'
                onClick={() => {
                  setExpandedKeys(expandedKeys.length > 0 ? [] : allKeys);
                }}
              >
                {expandedKeys.length > 0 ? '모두 접기' : '모두 펼치기'}
              </Button>
              {/* <Button className='w-1/2' onClick={() => setDraggable(!draggable)}>
            편집
          </Button> */}
            </div>

            <NodeNew
              tags={[...new Set(treeData.map((x) => x.name))].sort()}
              onSubmit={async (value: string) => {
                if (!value) {
                  return;
                }
                setLoading(true);
                try {
                  await API.Category.Create(
                    value,
                    selectedNode
                      ? (selectedNode.path ?? ',') + selectedNode.title + ','
                      : undefined
                  );

                  await fetchData();
                } catch (e) {
                  toast.error('실패');
                  console.log(e);
                }
                setLoading(false);
              }}
            />

            <NodeEdit
              disabled={!selectedNode}
              initialValue={selectedNode?.title || ''}
              onSubmit={async (value: string) => {
                setLoading(true);
                try {
                  await API.Category.Update(selectedNode?.key, value);
                  await fetchData();
                } catch (e) {
                  toast.error('실패');
                  console.log(e);
                }
                setLoading(false);
              }}
            />

            <NodeDelete
              disabled={!selectedNode}
              name={selectedNode?.title || ''}
              onSubmit={async () => {
                setLoading(true);
                try {
                  await API.Category.Delete(selectedNode?.key.toString());
                  await fetchData();
                } catch (e) {
                  toast.error('실패');
                  console.log(e);
                }
                setLoading(false);
              }}
            />
          </div>
        </div>
      </div>
    </Spin>
  );
}
