import axios from 'axios';
import { jwtUtils } from './JwtUtils';

const REST_BASE_URL = process.env.REACT_APP_REST_BASE_URL || '';

const instance = axios.create({
  baseURL: REST_BASE_URL,
});

/**
    1. 요청 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.request.use(
  (config) => {
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    const token = sessionStorage.getItem('accessToken');
    try {
      if (token && jwtUtils.isAuth(token)) {
        config.headers && (config.headers.Authorization = `Bearer ${token}`);
      } else {
        console.log('isAuth not');
      }

      return config;
    } catch (err: any) {
      console.error('[_axios.interceptors.request] config : ' + err.message);
    }
    return config;
  },
  (error) => {
    // 요청 에러 직전 호출됩니다.
    return Promise.reject(error);
  }
);

/**
    2. 응답 인터셉터
    2개의 콜백 함수를 받습니다.
*/
instance.interceptors.response.use(
  (response) => {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다.
        .then() 으로 이어집니다.
    */

    return response;
  },

  (error) => {
    /*
        http status가 200이 아닌 경우
        응답 에러 직전 호출됩니다.
        .catch() 으로 이어집니다.
    */
    return Promise.reject(error);
  }
);

export interface IRepository {
  _id: string;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  author: string;
  path: string;
  description: string;
  refRepositoryId?: string;
  refRepository?: IRepository;
}

namespace API {
  export namespace User {
    export async function Login(
      username: string,
      password: string
    ): Promise<{ data: { accessToken: string; nickname: string } }> {
      return await axios.post(
        process.env.REACT_APP_REST_BASE_URL + '/auth/login',
        {
          username: username,
          password: password,
        }
      );
    }
    // export async function Logout() {
    //   return instance.post('/auth/logout');
    // }
    export async function Create({
      username,
      password,
      nickname,
      email,
    }: {
      username: string;
      password: string;
      nickname: string;
      email: string;
    }) {
      return instance.post('/users', {
        username: username,
        password: password,
        nickname: nickname,
        email: email,
      });
    }

    export async function GetInfo() {
      return instance.get('/users/info');
    }
  }

  export namespace Repositories {
    const URL = '/repositories';

    interface CreateParams {
      title: string;
      description?: string;
      path: string;
      author: string;
      refRepositoryId?: string;
    }
    export async function Create(params: CreateParams) {
      return await instance.post(URL, params);
    }

    export async function Read(query: string) {
      return await instance.get(URL + '?' + query);
    }

    export async function ReadOneById(id: number): Promise<IRepository> {
      const resp = await instance.get(URL + '/' + id);
      return {
        ...resp.data,
        createdAt: new Date(resp.data.createdAt),
        updatedAt: new Date(resp.data.updatedAt),
        refRepository: resp.data.refRepository[0],
      };
    }

    export async function ReadOneByObjectId(id: string): Promise<IRepository> {
      const resp = await instance.get(URL + '/objectid/' + id);
      return { ...resp.data, refRepository: resp.data.refRepository[0] };
    }

    interface UpdateParams {
      title?: string;
      description?: string;
      path?: string;
      refRepositoryId?: string;
    }
    export async function Update(id: number, params: UpdateParams) {
      return await instance.patch(URL + '/' + id, params);
    }

    export async function Delete(id: number) {
      return await instance.delete(URL + '/' + id);
    }
  }

  export namespace Releases {
    const URL = '/releases';

    export async function Create(data: FormData, onUploadProgress?: any) {
      return await instance.post(URL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: onUploadProgress,
      });
    }

    export async function Read(query: string) {
      return await instance.get(URL + '?' + query);
    }

    export async function ReadManyByRepositoryId(repositoryId: string) {
      return await instance.get(URL, {
        params: { repositoryId: repositoryId },
      });
    }

    export async function ReadOneById(id: number) {
      return await instance.get(URL + '/' + id);
    }

    export async function Update(id: number, data: FormData) {
      return await instance.patch(URL + '/' + id, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }

    export async function Delete(id: string) {
      return await instance.delete(URL + '/' + id);
    }

    export async function FileDownload(
      id: number,
      onDownload: (size: number) => void
    ) {
      return await instance.get('/release-files/' + id + '/download', {
        responseType: 'blob',
        onDownloadProgress(progressEvent) {
          onDownload(progressEvent.loaded);
        },
      });
    }
  }

  export namespace Category {
    const URL = '/categories';

    export async function Create(name: string, path: string | undefined) {
      return await instance.post(URL, { name: name, path: path });
    }

    export async function Update(id: string | undefined, name: string) {
      return await instance.patch(URL + '/' + id, { name: name });
    }

    export async function Read() {
      return await instance.get(URL);
    }

    export async function Delete(id: string | undefined) {
      return await instance.delete(URL + '/' + id);
    }
  }
}

export default API;
