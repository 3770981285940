import type { ColumnType } from 'antd/lib/table';
import Tooltip from 'antd/es/tooltip';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { RenderCategories, RenderFileSize } from './Common';

export async function DownloadHandler(id: number, text: string) {
  try {
    const resp = await fetch(
      process.env.REACT_APP_REST_BASE_URL + '/release/download/' + id,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + sessionStorage.getItem('accessToken'),
        },
      }
    );

    if (resp.ok === false) {
      toast.error('파일을 찾지 못했습니다');
      return;
    }

    const newHandle = await window.showSaveFilePicker({
      suggestedName: text,
    });
    if (newHandle) {
      const writableStream = await newHandle.createWritable();
      const blob = await resp.blob();
      await writableStream.write(blob);
      await writableStream.close();
      toast.success('파일 다운로드 완료');
      console.log('saved');
    } else {
      console.log('no file handle');
    }
  } catch (e) {
    console.log('download exception:', e);
  }
}

export interface IRelease {
  key: string;

  createdAt: Date;
  updatedAt: Date;

  id: number;
  version: string;
  status: string;
  author: string;
  description: string;
  files: any[];
}

export const ColumnID: ColumnType<IRelease> = {
  title: 'ID',
  dataIndex: 'id',
  key: 'id',
  width: 30,
  sorter: true,
  render: (value) => {
    return <div className='w-min'>{value}</div>;
  },
};

export const ColumnCategory: ColumnType<IRelease> = {
  title: '카테고리',
  dataIndex: 'categories',
  key: 'categories',
  render: (categories: string[]) => (
    <div className='w-min flex flex-row flex-nowrap'>
      {categories ? RenderCategories(categories) : null}
    </div>
  ),
};

export const ColumnVersion: ColumnType<IRelease> = {
  title: '버전',
  dataIndex: 'version',
  key: 'version',
  width: 180,
  sorter: true,
  render: (value) => <span className='truncate'>{value}</span>,
};

export const ColumnReleaseStatus: ColumnType<IRelease> = {
  title: '상태',
  dataIndex: 'status',
  key: 'status',
  sorter: true,
  width: 80,
  render: (value) => {
    switch (value) {
      case '승인':
        return (
          <div className='font-bold text-green-600 flex items-center truncate'>
            {value}
            <CheckOutlined className='ml-1' />
          </div>
        );

      default:
        return <div>{value}</div>;
    }
  },
};

export const ColumnFilesize: ColumnType<IRelease> = {
  title: '크기',
  dataIndex: 'filesize',
  key: 'filesize',
  width: 80,
  sorter: true,
  render: (value) => (
    <div className='text-right min-w-fit w-16'>
      {value ? RenderFileSize(value) : null}
    </div>
  ),
};

export const ColumnAuthorNickname: ColumnType<IRelease> = {
  title: '작성자',
  dataIndex: 'authorNickname',
  key: 'authorNickname',
  width: 80,
  render: (value) => <span className='truncate'>{value}</span>,
};

export const ColumnCreatedAt: ColumnType<IRelease> = {
  title: '작성일',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 90,
  render: (date: string) => {
    return (
      <Tooltip title={new Date(date).toLocaleString()}>
        {format(new Date(date), 'yyyy/MM/dd')}
      </Tooltip>
    );
  },
  sorter: true,
};

export const ColumnUpdatedAt: ColumnType<IRelease> = {
  title: '수정일',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  width: 90,
  render: (date: string) => {
    return (
      <Tooltip title={new Date(date).toLocaleString()}>
        {format(new Date(date), 'yyyy/MM/dd')}
      </Tooltip>
    );
  },
  sorter: true,
};
