import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import API from '../utils/api';
import { Title, Categories, Description } from '../components/Repository';
import { ModalSpinner } from '../components/Modal';
import { IRepository } from '../utils/api';

export default function RepositoryEditPage() {
  const params = useParams();
  const id = parseInt(params.id ?? '-1');
  const navigate = useNavigate();
  const [repository, setRepository] = useState<IRepository>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const resp = await API.Repositories.ReadOneById(id);
        setRepository(resp);
      } catch (e) {
        console.log(e);
        toast.error('릴리즈 그룹을 불러오지 못했습니다');
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
    }
    fetchData();
  }, [id, navigate]);

  return repository ? (
    <Formik
      initialValues={{
        title: repository.title,
        path: repository.path,
        description: repository.description,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('제목을 입력해주세요'),
        path: Yup.string().required('카테고리를 선택해주세요'),
      })}
      onSubmit={async (values) => {
        setIsOpen(true);
        try {
          const savedDoc = await API.Repositories.Update(id, values);
          toast.success('릴리즈 그룹 저장 성공');
          navigate('/repositories/' + savedDoc.data.id);
        } catch (e: any) {
          toast.error('릴리즈 그룹 저장을 실패하였습니다');
          console.log('onSubmit exception', e);
        }
        setIsOpen(false);
      }}
    >
      {({ errors, values, setFieldValue }) => (
        <div className='sm:m-8 w-100 flex justify-center'>
          <Form className='p-5 space-y-8 w-[1000px]' acceptCharset='UTF-8'>
            <div className='space-y-8 sm:space-y-5'>
              <div className='space-y-6 sm:space-y-5'>
                <h3 className='text-2xl leading-6 font-bolder text-gray-900'>
                  게시글 편집
                </h3>

                <Title />
                <Categories
                  values={values.path
                    ?.split(',')
                    .filter((x: string) => x.length)}
                  onChange={(v: any) => {
                    setFieldValue(
                      'path',
                      v ? ',' + v.join(',') + ',' : undefined
                    );
                  }}
                />

                <Description />

                <div className='grid grid-cols-12 items-center'>
                  <div className='text-lg font-bolder'>참조:</div>
                  <Link
                    className='col-span-10'
                    to={'/repositories/' + repository.refRepository?.id}
                    reloadDocument={true}
                  >
                    {repository.refRepository?.title}
                  </Link>
                </div>
              </div>

              <div className='pt-5'>
                <div className='flex justify-start'>
                  <button
                    type='submit'
                    className='w-24 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    저장
                  </button>
                  <button
                    type='button'
                    className='ml-3 w-24 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </Form>
          <ModalSpinner open={isOpen} setOpen={setIsOpen} />
        </div>
      )}
    </Formik>
  ) : (
    <div />
  );
}
