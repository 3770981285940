import { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthProvider';
import { ModalSpinner } from '../components/Modal';
import API from '../utils/api';
import { Title, Categories, Description } from '../components/Repository';
import { RenderCategoriesFromPath, RenderFileSize } from '../components/Common';
import Collapse from 'antd/es/collapse';
import { useUsersInfoContext } from '../context/UserInfoProvider';
import { IRepository } from '../utils/api';
import { IRelease } from '../components/ReleaseTableColumns';

export default function RepositoryNewPage() {
  const navigate = useNavigate();
  const state: any = useLocation().state;
  const [isOpen, setIsOpen] = useState(false);
  const { username } = useAuth().user;
  const { getNickname } = useUsersInfoContext();

  const refRepository: IRepository = state?.refRepository;
  const [releases, setReleases] = useState<IRelease[]>([]);

  const fetchReleases = useCallback(async () => {
    try {
      const resp = await API.Releases.ReadManyByRepositoryId(refRepository._id);

      setReleases(
        resp.data.map((doc: any) => {
          return {
            ...doc,
            key: doc._id,
            author: doc.author,
            authorNickname: getNickname(doc.author) ?? doc.author,
            filename: doc.files[0].name,
            filesize: doc.files[0].size,
          };
        })
      );
    } catch (e) {
      console.log(e);
    }
  }, [getNickname, refRepository?._id]);

  useEffect(() => {
    if (refRepository) {
      fetchReleases();
    }
  }, [fetchReleases, refRepository]);

  async function onSubmit(values: any) {
    try {
      const savedDoc = await API.Repositories.Create({
        ...values,
        refRepositoryId: refRepository?._id,
      });
      toast.success('릴리즈 그룹 저장 성공');
      navigate('/repositories/' + savedDoc.data.id);
    } catch (e: any) {
      toast.error('릴리즈 그룹 저장을 실패하였습니다');
      console.log('onSubmit exception', e);
    }
  }

  return (
    <Formik
      initialValues={{
        title: '',
        path: '',
        description: undefined,
        author: username,
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required('제목을 입력해주세요'),
        path: Yup.string().required('카테고리를 선택해주세요'),
      })}
      onSubmit={async (values) => {
        console.log('onSubmit', values);
        setIsOpen(true);
        await onSubmit(values);
        setIsOpen(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <div className='sm:m-8 w-100 flex justify-center'>
          <Form className='p-5 space-y-8 w-[1000px]' acceptCharset='UTF-8'>
            <div className='space-y-8  sm:space-y-5'>
              <div className='space-y-6 sm:space-y-5'>
                <h3 className='text-2xl leading-6 font-bolder text-gray-900'>
                  게시판 등록
                </h3>

                <Title />
                <Categories
                  values={values.path?.split(',').filter((x) => x.length)}
                  onChange={(v: any) => {
                    setFieldValue(
                      'path',
                      v ? ',' + v.join(',') + ',' : undefined
                    );
                  }}
                />

                <Description />

                {refRepository ? (
                  <div className='grid grid-cols-12 gap-4 items-center'>
                    <div className=''>참조</div>
                    <div className='col-span-10 border rounded p-5'>
                      <div className='space-y-2'>
                        <div className='flex space-x-3'>
                          <Link to={'/repositories/' + refRepository.id}>
                            {refRepository.title}
                          </Link>
                        </div>
                        <div>
                          {RenderCategoriesFromPath(refRepository.path ?? '')}
                        </div>
                        <div>{getNickname(refRepository.author)}</div>
                        <Collapse accordion bordered={false}>
                          <Collapse.Panel
                            className='bg-green-200'
                            key={1}
                            header={
                              <div>
                                {refRepository.description.slice(0, 40)}
                                {refRepository.description.length > 40
                                  ? '...'
                                  : null}
                              </div>
                            }
                          >
                            <textarea
                              readOnly
                              className='w-full h-36 border-none bg-green-200'
                              value={refRepository.description}
                            />
                          </Collapse.Panel>
                          {releases.length ? (
                            <Collapse.Panel
                              className='bg-yellow-200'
                              key={2}
                              header={
                                <div className='grid grid-cols-12'>
                                  <div className='col-span-2'>
                                    {releases[0].version}
                                  </div>
                                  <div className='col-span-9'>
                                    {releases[0].files[0].name}
                                  </div>
                                  <div className=''>
                                    {RenderFileSize(releases[0].files[0].size)}
                                  </div>
                                </div>
                              }
                            >
                              {releases.map((x) => (
                                <div key={x.key} className='grid grid-cols-12'>
                                  <div className='col-span-2'>{x.version}</div>
                                  <div className='col-span-9'>
                                    {x.files[0].name}
                                  </div>
                                  <div className=''>
                                    {RenderFileSize(x.files[0].size)}
                                  </div>
                                </div>
                              ))}
                            </Collapse.Panel>
                          ) : null}
                        </Collapse>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className='pt-5'>
                <div className='flex justify-start'>
                  <button
                    type='submit'
                    className='w-24 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    저장
                  </button>
                  <button
                    type='button'
                    className='ml-3 w-24 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </Form>
          <ModalSpinner open={isOpen} setOpen={setIsOpen} />
        </div>
      )}
    </Formik>
  );
}
