// import webcrypto, { BinaryLike } from 'crypto';
// import { promisify } from 'node:util';
// import config from '../config/config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ModalSpinner } from '../components/Modal';
import API from '../utils/api';
import { toast } from 'react-toastify';

// async function EncryptAES(payload: string, iv: BinaryLike): Promise<string> {
//   const scryptAsync = promisify(webcrypto.scrypt);
//   const key = (await scryptAsync(config.passSecret, 'salt', 256 / 8)) as Buffer;
//   const cipher = webcrypto.createCipheriv('aes-256-gcm', key, iv);
//   let encrypted = cipher.update(payload, 'utf8', 'utf8');
//   encrypted += cipher.final('utf8');
//   return encrypted;
// }

function ColoredErrorMessage({ name }: { name: string }) {
  return (
    <ErrorMessage component='a' className='' name={name}>
      {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
    </ErrorMessage>
  );
}

function UserID() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
      <label
        htmlFor='userid'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        ID
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='userid'
          name='userid'
          type='text'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='userid' />
      </div>
    </div>
  );
}

function UserPassword() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
      <label
        htmlFor='password'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        Password
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='password'
          name='password'
          type='password'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='password' />
      </div>
    </div>
  );
}

function UserPasswordConfirm() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
      <label
        htmlFor='passwordconfirm'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        Password 확인
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='passwordconfirm'
          name='passwordconfirm'
          type='password'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='passwordconfirm' />
      </div>
    </div>
  );
}

function UserName() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
      <label
        htmlFor='username'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        이름
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='username'
          name='username'
          type='text'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='username' />
      </div>
    </div>
  );
}

function Email() {
  return (
    <div className='sm:grid sm:grid-cols-12 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5'>
      <label
        htmlFor='email'
        className='block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2'
      >
        Email
      </label>
      <div className='mt-1 sm:mt-0 sm:col-span-10'>
        <Field
          id='email'
          name='email'
          type='email'
          className='max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md'
        />
        <ColoredErrorMessage name='email' />
      </div>
    </div>
  );
}

export default function UserRegisterPage() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        passwordconfirm: '',
        nickname: '',
        email: '',
      }}
      validationSchema={Yup.object().shape({
        userid: Yup.string()
          .min(6, '6자리 이상이어야 합니다')
          .max(16, '16자리 이하이어야 합니다.')
          .required('ID를 입력해주세요'),
        password: Yup.string()
          .min(8, '8자리 이상이어야 합니다')
          .max(16, '16자리 이하이어야 합니다.')
          .required('Password를 입력해주세요'),
        passwordconfirm: Yup.string()
          .min(8, '8자리 이상이어야 합니다')
          .max(16, '16자리 이하이어야 합니다.')
          .oneOf([Yup.ref('password'), null], '암호 확인이 일치하지 않습니다.')
          .required('Password를 한번 더 입력해주세요'),
        username: Yup.string().required('이름을 입력해주세요'),
        email: Yup.string().required('email 주소를 입력해주세요'),
      })}
      onSubmit={async (values) => {
        console.log(values);
        setIsOpen(true);
        try {
          const resp = await API.User.Create({
            username: values.username,
            password: values.password,
            nickname: values.nickname,
            email: values.email,
          });

          console.log(resp.data);
          if (resp.data.result_code === 201) {
            toast.error(
              <div>
                <div>같은 사용자가 이미 존재합니다</div>
                <div> 사용자 등록에 실패하였습니다</div>
              </div>
            );
          } else {
            toast.success('사용자 등록 성공');
            navigate('/');
          }
        } catch (e) {
          console.log(e);
          toast.error('사용자 등록에 실패하였습니다');
        }
        setIsOpen(false);
      }}
    >
      {() => (
        <div className='m-8 w-100 flex justify-center'>
          <Form
            className='border rounded p-5 shadow space-y-8 divide-y divide-gray-200 w-[1000px]'
            acceptCharset='UTF-8'
          >
            <div className='space-y-8 divide-y divide-gray-200 sm:space-y-5'>
              <div className='space-y-6 sm:space-y-5'>
                <h3 className='text-2xl leading-6 font-bolder text-gray-900'>
                  사용자 등록
                </h3>

                <UserID />
                <UserPassword />
                <UserPasswordConfirm />
                <UserName />
                <Email />
              </div>
              <div className='pt-5'>
                <div className='flex justify-start'>
                  <button
                    type='submit'
                    className='w-24 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  >
                    저장
                  </button>
                  <button
                    type='button'
                    className='ml-3 w-24 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    취소
                  </button>
                </div>
              </div>
            </div>
          </Form>
          <ModalSpinner open={isOpen} setOpen={setIsOpen} />
        </div>
      )}
    </Formik>
  );
}
