import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from 'antd/es/table';
// import { CascadeSelector } from '../components/CascadeSelector';
import API from '../utils/api';
// import { ArrayToCascadeTree } from '../utils/tree';
import Button from 'antd/es/button';
import {
  ColumnID,
  // ColumnCategory,
  ColumnTitle,
  ColumnDescription,
  ColumnAuthorNickname,
  ColumnRefRepository,
  ColumnCreatedAt,
  ColumnUpdatedAt,
} from '../components/RepositoryTable';
import { IRepository } from '../utils/api';
import { useUsersInfoContext } from '../context/UserInfoProvider';
import { RenderCategories } from '../components/Common';
import { CategoryTree } from '../components/CategoryTree';

function RepositoryListPage() {
  //categories
  // const [loadingCategoryData, setLoadingCategoryData] = useState(false);
  // const [categoryData, setCategoryData] = useState([]);
  const [categories, setCategories] = useState<string[]>([]);

  //repositories
  const [loading, setLoading] = useState(false);
  const [repositories, setRepositories] = useState<IRepository[]>([]);

  //repositories pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocs, setTotalDocs] = useState(1);
  const [sort, setSort] = useState({ id: -1 });

  const { getNickname } = useUsersInfoContext();

  // const fetchCategoryData = useCallback(async () => {
  //   setLoadingCategoryData(true);
  //   try {
  //     const resp = await API.Category.Read();
  //     setCategoryData(resp.data);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   setLoadingCategoryData(false);
  // }, []);

  const fetchRepositories = useCallback(async () => {
    const query = new URLSearchParams();
    if (categories?.length) {
      query.set('path', ',' + categories.join(',') + ',');
    }

    query.set('sort', JSON.stringify(sort));
    query.set('page', page.toString());
    query.set('pageSize', pageSize.toString());

    setLoading(true);
    try {
      const resp = await API.Repositories.Read(query.toString());
      const { docs, totalDocs } = resp.data;

      const tableData: IRepository[] = docs.map((doc: any) => {
        return {
          ...doc,
          key: doc._id,
          createdAt: new Date(doc.createdAt),
          updatedAt: new Date(doc.updatedAt),
          authorNickname: getNickname(doc.author) ?? doc.author,
        };
      });

      setRepositories(tableData);
      setTotalDocs(totalDocs);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, [categories, page, pageSize, sort, getNickname]);

  // useEffect(() => {
  //   fetchCategoryData();
  // }, [fetchCategoryData]);

  useEffect(() => {
    fetchRepositories();
  }, [fetchRepositories]);

  const Columns = [
    ColumnID,
    // ColumnCategory,
    ColumnTitle,
    ColumnDescription,
    ColumnAuthorNickname,
    ColumnCreatedAt,
    ColumnUpdatedAt,
    ColumnRefRepository,
  ];

  function CategoryTags() {
    const tags = RenderCategories(categories);
    return (
      <div className='boder rounded bg-slate-100 w-full h-8 p-1'>
        {tags.length ? tags : ''}
      </div>
    );
  }

  return (
    <div className='p-5 space-y-5 space-x-5'>
      {/* <CascadeSelector
        options={ArrayToCascadeTree(categoryData)}
        value={categories}
        onChange={setCategories}
        isLoading={loadingCategoryData}
      /> */}

      <div className='grid grid-cols-12'>
        <div className='col-span-2'>
          <CategoryTree
            onSelect={(node) => {
              const fullpath = node
                ? (node.path ?? ',') + node.title + ','
                : '';
              setCategories(
                fullpath ? fullpath.split(',').filter((x) => x.length) : []
              );
            }}
          />
        </div>
        <div className='col-span-10 space-y-5'>
          <div className='flex items-center space-x-5'>
            <Button type='primary'>
              <Link to='/repositories/new'>등록</Link>
            </Button>
            <CategoryTags />
          </div>
          <Table
            bordered
            columns={Columns}
            dataSource={repositories}
            pagination={{
              position: ['bottomCenter'],
              current: page,
              pageSize: pageSize,
              total: totalDocs,
              showSizeChanger: true,
            }}
            loading={loading}
            onChange={(newPagination, filters, sorter, extra) => {
              switch (extra.action) {
                case 'paginate': {
                  setPage(newPagination.current ?? 1);
                  setPageSize(newPagination.pageSize ?? 10);
                  break;
                }
                case 'sort': {
                  let newSort: any = {};

                  if (!Array.isArray(sorter) && sorter.field) {
                    newSort[sorter.field?.toString()] =
                      sorter.order === 'ascend'
                        ? 1
                        : sorter.order === 'descend'
                        ? -1
                        : undefined;
                  }

                  setSort(newSort);
                  break;
                }
                case 'filter':
                  break;
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default RepositoryListPage;
