import Cascader from 'antd/lib/cascader';
import { CascadeNode } from '../utils/tree';
import { RenderCategories } from './Common';

export interface CascadeSelectorProps {
  className?: string;
  options: CascadeNode[];
  value: any;
  onChange: (v: any) => void;
  isLoading?: boolean;
}

export function CascadeSelector({
  className,
  options,
  value,
  onChange,
  isLoading,
}: CascadeSelectorProps) {
  return (
    <Cascader
      id='categories'
      className={className}
      style={{ width: 'auto' }}
      size='large'
      options={options}
      displayRender={(labels) => RenderCategories(labels)}
      value={value}
      onChange={onChange}
      placeholder='종류를 선택하세요'
      placement='bottomLeft'
      expandTrigger='click'
      changeOnSelect
      loading={isLoading}
    />
  );
}
