export interface DocType {
  _id: object;
  name: string;
  path?: string;
}
export interface TreeNode {
  title: string;
  key: string;
  path?: string;
  children?: TreeNode[];
}

export interface CascadeNode {
  value: string;
  label: string;
  key: string;
  path?: string;
  children?: CascadeNode[];
}

export function ArrayToTree(DBDocs: any): TreeNode[] {
  //
  function makeChildren(docs: any[], parent: TreeNode): TreeNode[] {
    const pathToParent = (parent.path ?? ',') + parent.title + ',';
    const children = docs.filter((doc: DocType) => pathToParent === doc.path);

    const nodes = children.map((child: DocType) => {
      return {
        title: child.name,
        key: child._id.toString(),
        path: child.path,
        children: makeChildren(
          docs.filter((doc) =>
            doc.path?.match(pathToParent + child.name + '.*')
          ),
          {
            title: child.name,
            key: child._id.toString(),
            path: child.path,
          }
        ),
      };
    });

    return nodes;
  }

  let roots: TreeNode[] = DBDocs.filter(
    (doc: DocType) => doc.path === undefined || doc.path.length === 0
  ).map((doc: DocType): TreeNode => {
    return { title: doc.name, key: doc._id.toString() };
  });

  for (let root of roots) {
    root.children = makeChildren(DBDocs, root);
  }

  return roots;
}

export function ArrayToCascadeTree(DBDocs: any): CascadeNode[] {
  function makeChildren(docs: any[], parent: CascadeNode): CascadeNode[] {
    const pathToParent = (parent.path ?? ',') + parent.value + ',';
    const children = docs.filter((doc: DocType) => pathToParent === doc.path);

    const nodes = children.map((child: DocType) => {
      return {
        label: child.name,
        value: child.name,
        key: child._id.toString(),
        path: child.path,
        children: makeChildren(
          docs.filter((doc) =>
            doc.path?.match(pathToParent + child.name + '.*')
          ),
          {
            label: child.name,
            value: child.name,
            key: child._id.toString(),
            path: child.path,
          }
        ),
      };
    });

    return nodes;
  }

  let roots: CascadeNode[] = DBDocs.filter(
    (doc: DocType) => doc.path === undefined || doc.path.length === 0
  ).map((doc: DocType): CascadeNode => {
    return { label: doc.name, value: doc.name, key: doc._id.toString() };
  });

  for (let root of roots) {
    root.children = makeChildren(DBDocs, root);
  }

  return roots;
}
