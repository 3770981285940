import { createContext, useContext, useState } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { jwtUtils } from '../utils/JwtUtils';
/*
 *
 */
namespace CurrentUserInfo {
  export function Get(storage: Storage): UserContext {
    return {
      username: storage.getItem('username') ?? '',
      nickname: storage.getItem('nickname') ?? '',
      accessToken: storage.getItem('accessToken') ?? '',
    };
  }

  export function Set(
    storage: Storage,
    { username, nickname, accessToken }: UserContext
  ) {
    storage.setItem('username', username);
    storage.setItem('nickname', nickname);
    storage.setItem('accessToken', accessToken);
  }

  export function Remove(storage: Storage) {
    storage.removeItem('username');
    storage.removeItem('nickname');
    storage.removeItem('accessToken');
  }
}

/*
 *
 */
export interface UserContext {
  username: string;
  nickname: string;
  accessToken: string;
}

interface AuthContextType {
  user: UserContext;
  signin: (user: UserContext, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState<any>(CurrentUserInfo.Get(sessionStorage));

  let signin = (newUser: UserContext, callback: VoidFunction) => {
    setUser(newUser);
    CurrentUserInfo.Set(sessionStorage, newUser);
    callback();
    // return fakeAuthProvider.signin(() => {
    //   callback();
    // });
  };

  let signout = (callback: VoidFunction) => {
    setUser(null);
    CurrentUserInfo.Remove(sessionStorage);
    callback();
    // return fakeAuthProvider.signout(() => {
    //   callback();
    // });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}

export function RequireAuth() {
  let auth = useAuth();
  let location = useLocation();

  if (
    !auth.user ||
    !auth.user.username ||
    !auth.user.accessToken ||
    !jwtUtils.isAuth(auth.user.accessToken)
  ) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return <Outlet />;
}
