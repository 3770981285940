import { Outlet } from 'react-router-dom';
import Header from './Header';
import { UsersInfoProvider } from '../context/UserInfoProvider';

export default function Layout() {
  return (
    <div>
      <UsersInfoProvider>
        <Header />
        <Outlet />
      </UsersInfoProvider>
    </div>
  );
}
