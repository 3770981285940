import { useState, useEffect, useCallback } from 'react';
import Table from 'antd/es/table';
import type { ColumnType } from 'antd/es/table';
import Popconfirm from 'antd/es/popconfirm';
import Progress from 'antd/es/progress';
import Popover from 'antd/es/popover';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import DownloadOutlined from '@ant-design/icons/DownloadOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { toast } from 'react-toastify';

import {
  IRelease,
  ColumnID,
  ColumnVersion,
  ColumnReleaseStatus,
  ColumnAuthorNickname,
  ColumnCreatedAt,
  ColumnUpdatedAt,
  ColumnFilesize,
} from './ReleaseTableColumns';
import API from '../utils/api';
import { useAuth } from '../context/AuthProvider';
import { useUsersInfoContext } from '../context/UserInfoProvider';
import { RenderFileSize, ModalHeadlessUI } from './Common';

export function ReleaseTable({
  readonly,
  repositoryId,
  repositoryUpdatedAt,
  onUpdate,
}: {
  readonly: boolean;
  repositoryId: string;
  repositoryUpdatedAt: Date;
  onUpdate: (release: IRelease) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [releases, setReleases] = useState<IRelease[]>([]);
  const { username } = useAuth().user;
  const { getNickname } = useUsersInfoContext();

  const [downloading, setDownloading] = useState(false);
  const [downloadSize, setDownloadSize] = useState(0);
  const [downloadedSize, setDownloadedSize] = useState(0);

  const ColumnFile: ColumnType<IRelease> = {
    title: '파일',
    dataIndex: 'filename',
    key: 'filename',
    render: (data, record, index) => {
      return (
        <div className='flex justify-between'>
          <Popover
            content={
              <textarea
                className='lg:w-[800px] bg-green-400 shadow-sm border-gray-300 rounded-md sm:h-52'
                style={{ whiteSpace: 'pre-wrap' }}
                value={record.description}
                readOnly={true}
              />
            }
          >
            {data}
          </Popover>
          <button
            className='text-blue-500 ml-3'
            onClick={async () => {
              const file = record.files[0];

              let newHandle;
              try {
                newHandle = await window.showSaveFilePicker({
                  suggestedName: file.name,
                });
              } catch (e) {
                toast.warning('다운로드 취소');
                return;
              }

              setDownloadedSize(0);
              setDownloadSize(file.size);
              setDownloading(true);
              try {
                const resp = await API.Releases.FileDownload(file.id, (size) =>
                  setDownloadedSize(size)
                );
                const writableStream = await newHandle.createWritable();
                const blob = await resp.data;
                await writableStream.write(blob);
                await writableStream.close();
                toast.success('다운로드 완료');
              } catch (e) {
                toast.success('다운로드 실패');
                console.log(e);
              }
              setDownloading(false);
            }}
          >
            <DownloadOutlined />
          </button>
        </div>
      );
    },
  };

  const ColumnOps: ColumnType<IRelease> = {
    key: 'ops',
    width: 30,
    render: (data, record, index) => {
      return !readonly && username === record.author ? (
        <div className='space-x-3 flex'>
          <button
            className='text-blue-500'
            onClick={async () => {
              onUpdate(record);
            }}
          >
            <EditOutlined />
          </button>

          <Popconfirm
            title={<div>정말 삭제하시겠습니까?</div>}
            placement='bottomLeft'
            okText='예'
            showCancel={false}
            onConfirm={async () => {
              setLoading(true);
              try {
                const resp = await API.Releases.Delete(
                  record.id.toString() ?? ''
                );
                console.log(resp);
                await fetchReleases();
                toast.success('삭제를 성공했습니다');
              } catch (e) {
                console.log(e);
                toast.success('삭제를 실패했습니다');
              }
              setLoading(false);
            }}
          >
            <button className='text-red-500'>
              <DeleteOutlined />
            </button>
          </Popconfirm>
        </div>
      ) : null;
    },
  };

  const Columns = [
    ColumnID,
    ColumnVersion,
    ColumnFile,
    ColumnFilesize,
    ColumnReleaseStatus,
    ColumnAuthorNickname,
    ColumnCreatedAt,
    ColumnUpdatedAt,
    ColumnOps,
  ];

  const fetchReleases = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await API.Releases.ReadManyByRepositoryId(repositoryId);

      setReleases(
        resp.data.map((doc: any) => {
          return {
            ...doc,
            key: doc._id,
            author: doc.author,
            authorNickname: getNickname(doc.author) ?? doc.author,
            filename: doc.files[0].name,
            filesize: doc.files[0].size,
          };
        })
      );
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  }, [repositoryId, getNickname]);

  useEffect(() => {
    fetchReleases();
  }, [fetchReleases, repositoryUpdatedAt]);

  return (
    <div className='flex justify-center'>
      <Table
        className='w-fit'
        bordered
        columns={Columns}
        dataSource={releases}
        loading={loading}
        pagination={false}
        showSorterTooltip={false}
      />
      <ModalHeadlessUI
        open={downloading}
        body={
          <div className='p-5'>
            <div>
              {RenderFileSize(downloadedSize)}/ {RenderFileSize(downloadSize)}
            </div>
            <Progress
              percent={Number(
                ((downloadedSize / downloadSize) * 100).toFixed(2)
              )}
            />
            <button />
          </div>
        }
      />
    </div>
  );
}
