import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import API from '../utils/api';

export interface UserInfo {
  username: string;
  nickname: string;
}

export interface UserInfoContextType {
  usersInfo: Map<string, string>;
  getNickname: (username: string) => string | undefined;
}

const UsersInfoContext = createContext<UserInfoContextType>(null!);

export function UsersInfoProvider({ children }: { children: ReactNode }) {
  const [usersInfo, setUsersInfo] = useState<Map<string, string>>(
    new Map<string, string>()
  );

  async function fetchUsersInfo() {
    try {
      const resp = await API.User.GetInfo();
      const newUsersInfo = new Map<string, string>();
      for (const user of resp.data) {
        newUsersInfo.set(user.username, user.nickname);
      }
      setUsersInfo(newUsersInfo);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchUsersInfo();
  }, []);

  function getNickname(username: string) {
    return usersInfo.get(username);
  }

  let value = { usersInfo, getNickname };

  return usersInfo.size ? (
    <UsersInfoContext.Provider value={value}>
      {children}
    </UsersInfoContext.Provider>
  ) : (
    <div />
  );
}

export function useUsersInfoContext() {
  return useContext(UsersInfoContext);
}
