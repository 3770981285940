import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useAuth } from '../context/AuthProvider';
import { ModalSpinner } from '../components/Modal';
import API from '../utils/api';

function Head() {
  return (
    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
      <img className='mx-auto h-12 w-auto' src='/download.png' alt='' />
      <h2 className='mt-6 text-center text-3xl text-gray-700 font-bolder'>
        리산테크 자료실 로그인
      </h2>
    </div>
  );
}

// function Checkbox({ id, text }: { id: string; text: string }) {
//   return (
//     <div className='flex items-center'>
//       <input
//         id={id}
//         name={id}
//         type='checkbox'
//         className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
//       />
//       <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
//         {text}
//       </label>
//     </div>
//   );
// }

function ColoredErrorMessage() {
  return (
    <ErrorMessage component='a' className='' name='id'>
      {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
    </ErrorMessage>
  );
}

export default function Login() {
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  let auth = useAuth();

  return (
    <Formik
      initialValues={{
        id: '',
        password: '',
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string().required('ID를 입력하세요'),
        password: Yup.string().required('Password를 입력하세요'),
      })}
      onSubmit={async (values) => {
        setIsOpen(true);

        try {
          const resp = await API.User.Login(values.id, values.password);
          const { accessToken, nickname } = resp.data;

          auth.signin(
            {
              username: values.id,
              nickname: nickname,
              accessToken: accessToken,
            },
            () => {
              navigate('/', { replace: true });
            }
          );
          toast.success('로그인 성공');
        } catch (e) {
          console.log(e);
          toast.error(
            <div className='w-auto'>ID 또는 Password가 일치하지 않습니다</div>
          );
        }
        setIsOpen(false);
      }}
    >
      <div className='align-middle h-screen'>
        <ModalSpinner open={isOpen} setOpen={setIsOpen} />
        <div className='min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <Head />

          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
              <Form className='space-y-6'>
                <label
                  className='block text-sm font-medium text-gray-700'
                  htmlFor='id'
                >
                  ID
                </label>
                <Field
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  id='id'
                  name='id'
                />
                <ColoredErrorMessage />

                <label
                  className='block text-sm font-medium text-gray-700'
                  htmlFor='password'
                >
                  Password
                </label>
                <Field
                  className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                  id='password'
                  name='password'
                  type='password'
                />
                <ColoredErrorMessage />

                {/* <div className='flex items-center justify-around'>
                  <Checkbox id='remember-me' text='로그인 유지' />
                  <Checkbox id='remember-id-password' text='ID/Password 저장' />
                </div> */}
                <button
                  type='submit'
                  className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                >
                  로그인
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Formik>
  );
}
